import { useMutation, useQuery } from "@tanstack/react-query";
import { ADMIN_SUPPORT_MODULE_ID } from "../constants/constants";

export const fetchUserOrders = async (uamId) => {
  const response = await fetch(
    `${process.env.REACT_APP_ONE_OMS_URL}/fetch-customer-orders-details`,
    {
      method: "POST",
      headers: {
        accept: "application/json",
        "secret-key": process.env.REACT_APP_ONE_OMS_SECRET_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uam_id: uamId }),
    }
  );

  if (!response.ok) {
    throw new Error(
      `Failed to fetch data: ${response.status} ${response.statusText}`
    );
  }

  const result = await response.json();
  return result;
};

export const updateOrderStatus = async ({ orderId, action }) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ONE_OMS_URL}/update-order-cancellation-request/${orderId}/${action}`,
      {
        method: "POST",
        headers: {
          accept: "application/json",
          "secret-key": process.env.REACT_APP_ONE_OMS_SECRET_KEY,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

const uploadCsv = async ({ file, selectedScriptId, uploaderUserId }) => {

  const url = new URL(
    `${process.env.REACT_APP_UPLOAD_SERVICE_URL
    }/upload-file?uploaded_by=${uploaderUserId}&selected_script_id=${selectedScriptId}`
  );

  const headers = {
    accept: "application/json",
    "secret-key": `${process.env.REACT_APP_UPLOAD_SERVICE_SECRET_KEY}`,
  };

  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(url, {
    method: "POST",
    headers,
    body: formData,
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(
      errorData.message || `Failed to upload file. Status: ${response.status}`
    );
  }

  const data = await response.json();

  if (data.status === "success") {
    return data.data;
  } else {
    throw new Error(
      data.message || "An error occurred while discarding the order."
    );
  }
};

export const useUploadCsv = () => {
  return useMutation({ mutationFn: uploadCsv });
};

const fetchHistory = async (user_id) => {
  const url = new URL(
    `${process.env.REACT_APP_UPLOAD_SERVICE_URL}/get-all-files?uploader_user_id=${user_id}&module_id=${ADMIN_SUPPORT_MODULE_ID}`
  );

  const headers = {
    accept: "application/json",
    "secret-key": process.env.REACT_APP_UPLOAD_SERVICE_SECRET_KEY,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    method: "GET",
    headers: headers,
    body: null,
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(
      errorData.message || `Failed to fetch history. Status: ${response.status}`
    );
  }

  const data = await response.json();

  if (data.status === "success") {
    return data.data;
  } else {
    throw new Error(
      data.message || "An error occurred while fetching pending orders."
    );
  }
};

export const useFetchHistory = (user_id) => {
  return useQuery({
    queryKey: ["history", user_id],
    queryFn: () => fetchHistory(user_id),
  });
};

const fetchProcessedFile = async (fileId) => {
  const url = new URL(
    `${process.env.REACT_APP_UPLOAD_SERVICE_URL}/download-processed-file/${fileId}`
  );

  const headers = {
    accept: "application/json",
    "secret-key": process.env.REACT_APP_UPLOAD_SERVICE_SECRET_KEY,
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    method: "GET",
    headers: headers,
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(
      errorData.message ||
      `Failed to fetch processed file. Status: ${response.status}`
    );
  }

  const data = await response.json();

  if (data.status === "success") {
    return { signedUrl: data.data.signed_url };
  } else {
    throw new Error(
      data.message || "An error occurred while fetching the processed file."
    );
  }
};

export const useFetchProcessedFile = (fileId) => {
  const query = useQuery({
    queryKey: ["processedFile", fileId],
    queryFn: () => fetchProcessedFile(fileId),
    enabled: !!fileId,
  });
  return {
    ...query,
    fetchProcessedFile,
  };
};

const unblockPhoneNumber = async (phoneNumber) => {

  const url = new URL(`${process.env.REACT_APP_RESET_OTP_URL}`);

  const payload = { isd_code: "+91", phone: phoneNumber };

  const headers = {
    accept: "application/json",
    "Content-Type": "application/json",
  };

  const response = await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(payload),
  });

  const data = await response.json();

  if (!data.success || data.success.toLowerCase() !== "unblocked user successfully") {
    throw new Error(data.message || `Error: ${response.statusText}`);
  }
  
  return data.detail || data.success;
  
};

export const useUnblockPhoneNumber = () => {
  return useMutation({ mutationFn: unblockPhoneNumber });
};