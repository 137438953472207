import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomDialog from '../../widgets/Dialog/Dialog';
import useFetchUserDetails from '../../store/backend/useFetchUserDetails';
import SecondaryButton from '../../widgets/Button/SecondaryButton/SecondaryButton';
import { useSnackbarContext } from "../../widgets/CustomSnackBar/CustomSnackBar";
import { useUnblockPhoneNumber } from '../../hooks/api';

import './SearchUserContainer.css';

const handleSearchResponse = (response, navigate, searchValue, setShowErrorPopup, setLoading) => {
    setLoading(false);
    if (response.isError || !response.data) {
        setShowErrorPopup(true);
    } else {
        localStorage.setItem("searchValue", searchValue);
        navigate(`/userdetails/UAM/${searchValue}`);
    }
};

const handleSearch = async (searchValue, refetchUserDetails, navigate, setShowErrorPopup, setLoading) => {
    if (searchValue.trim()) {
        setLoading(true);
        const response = await refetchUserDetails();
        handleSearchResponse(response, navigate, searchValue, setShowErrorPopup, setLoading);
    }
};

const handleOnClickResetOtpButton = (phoneNumber, unblockPhoneNumber, openSnackbar) => {
    unblockPhoneNumber(phoneNumber, {
        onSuccess: (data) => {
            openSnackbar(data.success || "OTP reset successful", "success");
        },
        onError: (error) => {
            openSnackbar(error.message || "Failed to reset OTP", "error");
        }
    });
}

const SearchUserContainer = () => {
    const navigate = useNavigate();
    const { openSnackbar } = useSnackbarContext();
    const [searchValue, setSearchValue] = useState('');
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("")
    const { refetch: refetchUserDetails } = useFetchUserDetails(searchValue, 'UAM');
    const { mutate: unblockPhoneNumber, isLoading: isUnblockPhoneNumberLoading } = useUnblockPhoneNumber()

    const handleSearchValueChange = (e) => setSearchValue(e.target.value);

    const handleCloseErrorPopup = () => setShowErrorPopup(false);

    return (
        <>
            <div className="search-user-container">
                <h1 className="search-user-title">Search User</h1>
                <div className="search-inputs">
                    <input
                        className="search-input"
                        type="text"
                        value={searchValue}
                        onChange={handleSearchValueChange}
                        placeholder="Enter UAM ID"
                        disabled={loading}
                    />
                    <SecondaryButton
                        className="search-button"
                        onClick={() => handleSearch(searchValue, refetchUserDetails, navigate, setShowErrorPopup, setLoading)}
                        disabled={loading}
                    >
                        {loading ? 'Searching...' : 'Search'}
                    </SecondaryButton>
                </div>
            </div>
            <div className='search-user-container'>
                <h1 className="search-user-title">Reset Otp</h1>
                <div className="search-inputs">
                    <input
                        className="search-input"
                        type="tel"
                        value={phoneNumber}
                        onChange={(event) => {
                            const inputVal = event.target.value.replace(/\D/g, "");
                            setPhoneNumber(inputVal.slice(0, 10));
                        }} placeholder="Enter a phone number"
                    />
                    <SecondaryButton onClick={() => handleOnClickResetOtpButton(phoneNumber, unblockPhoneNumber, openSnackbar)}
                        disabled={isUnblockPhoneNumberLoading}>
                        {isUnblockPhoneNumberLoading ? "Loading" : "Reset Otp"}</SecondaryButton>
                </div>

            </div>
            <CustomDialog isOpen={showErrorPopup} handleOnClose={handleCloseErrorPopup}>
                <div className="popup-content">
                    <p>User not found. Please try again.</p>
                    <div className="button-container">
                        <SecondaryButton onClick={handleCloseErrorPopup}>
                            Close
                        </SecondaryButton>
                    </div>
                </div>
            </CustomDialog>
        </>

    );
};

export default SearchUserContainer;